<template>
  <main>
    <div class="form">
      <div class="done">
        <h2>Nous vous confirmons l'enregistrement de votre participation.</h2>
        <p>
          Nous traitons votre demande. Vous recevrez un mail de confirmation
          sous réserve de conformité de votre dossier.
        </p>
        <br />
        <br />
        <div width="100%" align="center">
          <router-link
            v-if="!questionnaire"
            :to="`/status/${submission_id}/${uid}`"
            class="button question"
            >Accèder au status</router-link
          >
         <!--  <router-link
            v-if="questionnaire"
            :to="`/questionnaire/${submission_id}/${uid}`"
            class="button question"
            >Répondre au questionnaire</router-link
          > -->
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";

export default {
  name: "Status",
  data: function() {
    return {
      questionnaire: false,
      hiderror: true,
      submission_id: undefined,
      status_form_id: undefined,
      questionnaire_form_id: undefined,
      server: "https://api.lucien.ai",
      uid: undefined,
    };
  },
  async mounted() {
    let data = window.sub_id.split("|");
    this.submission_id = data[0];
    this.uid = data[1];

    console.log(this.submission_id, this.uid);

    try {
      let { data } = await axios.get(
        "https://api.lucien.ai/api/v2/submissions/" +
          this.submission_id +
          "?uid=" +
          this.uid
      );

      if (data.question_1) {
        this.questionnaire = false;
      } else {
        this.questionnaire = true;
      }
    } catch (e) {
      console.log(e);
      this.$swal({
        type: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        confirmButtonColor: "#C94330",
        allowOutsideClick: false,
        html:
          "Identifiants non reconnus.<br > Merci de suivre le lien transmit par email.",
      }).then(function() {
        document.location.href = "/";
      });
      return;
    }
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          allowOutsideClick: false,
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult: function(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html:
            "<b>Votre status a bien été mises à jour. Vous serez informé de sa validation par email.</b>",
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          allowOutsideClick: false,
          text: "Une erreur est survenue",
        });
      }
    },
    getResult2: function(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          allowOutsideClick: false,
          html:
            "<b>Merci d'avoir répondu à notre questionnaire. Vous êtes à présent inscrit pour le tirage au sort.</b>",
        }).then((result) => {
          window.location = "/status/" + this.submission_id + "|" + this.uid;
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          allowOutsideClick: false,
          text: "Une erreur est survenue",
        });
      }
    },
    viewError(err) {
      console.log(err);

      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        allowOutsideClick: false,
        title: "Désolé, une erreur est survenue.",
        text: err,
      }).then(function() {
        window.location.href = "/";
      });
    },
  },
};
</script>

<style lang="scss">
$blue: #273a60;
$orange: #eb4f15;
.button,
#back_button {
  display: block;
  cursor: pointer;
  padding: 10px 30px;
  text-align: center;
  background-color: $orange;
  border: 1px solid $orange;
  font-size: 1.5em;
  color: white !important;
  text-transform: uppercase !important;
  font-family: "Trade Gothic";
  width: 250px;
  font-weight: bold;

  &:hover {
    background-color: $blue;
    border: 1px solid $blue;

    @media screen and (max-width: 768px) {
      background-color: $orange;
    }
  }

  @media screen and (max-width: 768px) {
    width: 48% !important;
    font-size: 17px;
  }
}

a {
  text-decoration: none;
  color: $blue;
  font-weight: bold;
}
</style>
